import type * as Sentry from '@sentry/node'

const SENTRY_TRACE_SAMPLE_RATE = 0.01
export const SENTRY_REPLAY_SAMPLE_RATE = 0.1
export const SENTRY_REPLAY_ERROR_SAMPLE_RATE = 1.0
export const SENTRY_NORMALIZE_DEPTH = 10

export const beforeSend = (event: Sentry.ErrorEvent): Sentry.ErrorEvent | null => {
  console.log('event', event.tags)
  if (event.tags?.always_capture) {
    return event
  }
  return Math.random() < SENTRY_TRACE_SAMPLE_RATE ? event : null
}
